import * as tslib_1 from "tslib";
import { TemplateRef, AfterViewInit } from '@angular/core';
import { Election, Candidate } from '@smartvote/common';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
var GetDatabaseElections = require('graphql-tag/loader!./database.page.graphql').GetDatabaseElections;
import { FilterGroupState } from './database-filter-group/database-filter-group.component';
var DatabasePage = /** @class */ (function () {
    function DatabasePage(apollo, router, route, _localStorage) {
        var _this = this;
        this.apollo = apollo;
        this.router = router;
        this.route = route;
        this._localStorage = _localStorage;
        this.filterGroupStateChanges = new BehaviorSubject(new FilterGroupState());
        this.loadingdata = false;
        this.showList = false;
        this.tabIndex = 0;
        this.elections = this.getElections();
        this.candidates = combineLatest([this.elections, this.filterGroupStateChanges]).pipe(map(function (_a) {
            var elections = _a[0], filterGroupState = _a[1];
            _this.loadingdata = false;
            var election = null;
            if (elections.length > 1) {
                election = elections.find(function (e) { return e.id === filterGroupState.election; });
            }
            else {
                election = elections[0];
            }
            if (election) {
                return _this.filterCandidates(election.candidates, filterGroupState).map(function (candidate) {
                    return tslib_1.__assign({}, candidate, { description: _this.getCandidateDescription(candidate) });
                });
            }
            return [];
        }));
        this.route.queryParamMap.subscribe(function (queryParamMap) {
            _this.tabIndex = parseInt(queryParamMap.get('tab'), 10) || 0;
        });
        var savedState = this._localStorage.getItem('profiles-filter-group');
        if (savedState) {
            this.onSearch(JSON.parse(savedState));
        }
    }
    DatabasePage.prototype.ngAfterViewInit = function () {
        this.translations = this.translationTemplate
            .createEmbeddedView({})
            .rootNodes.reduce(function (prev, curr) {
            var _a;
            return (tslib_1.__assign({}, prev, (_a = {}, _a[curr.id] = curr.textContent, _a)));
        }, {});
    };
    DatabasePage.prototype.getElections = function () {
        return this.apollo
            .query({
            query: GetDatabaseElections
        })
            .pipe(map(function (_a) {
            var data = _a.data;
            return data.elections;
        }));
    };
    DatabasePage.prototype.onSearch = function (state) {
        this.loadingdata = true;
        this.showList = true;
        this._localStorage.setItem('profiles-filter-group', JSON.stringify(state));
        this.filterGroupStateChanges.next(state);
    };
    DatabasePage.prototype.onReset = function () {
        this._localStorage.setItem('profiles-filter-group', '');
        this.showList = false;
    };
    DatabasePage.prototype.onTabChanged = function (index) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                tab: index
            },
            replaceUrl: true,
            queryParamsHandling: 'merge'
        });
    };
    DatabasePage.prototype.navigateToCandidate = function (candidateId, electionId) {
        this.router.navigate(['profiles', 'candidate', candidateId, electionId]);
    };
    DatabasePage.prototype.navigateToParty = function (partyId) {
        this.router.navigate(['profiles', 'party', partyId]);
    };
    DatabasePage.prototype.filterCandidates = function (candidates, filterGroupState) {
        return candidates.filter(function (candidate) {
            if (filterGroupState.district) {
                if (!(candidate.district && candidate.district.id === filterGroupState.district)) {
                    return false;
                }
            }
            if (filterGroupState.incumbent !== '') {
                if (!((candidate.incumbent && filterGroupState.incumbent) ||
                    (!candidate.incumbent && !filterGroupState.incumbent))) {
                    return false;
                }
            }
            if (filterGroupState.elected !== '') {
                if (!((candidate.elected && filterGroupState.elected) ||
                    (!candidate.elected && !filterGroupState.elected))) {
                    return false;
                }
            }
            if (filterGroupState.gender) {
                if (!(candidate.gender.toString() === filterGroupState.gender)) {
                    return false;
                }
            }
            if (filterGroupState.answersConfirmed !== '') {
                if (filterGroupState.answersConfirmed === 'false'
                    && Number(candidate.answersConfirmed) !== 0
                    && candidate.answersConfirmed !== false
                    && String(candidate.answersConfirmed) !== '0'
                    && String(candidate.answersConfirmed) !== 'false') {
                    return false;
                }
                if (filterGroupState.answersConfirmed === 'true'
                    && Number(candidate.answersConfirmed) !== 1
                    && candidate.answersConfirmed !== true
                    && String(candidate.answersConfirmed) !== '1'
                    && String(candidate.answersConfirmed) !== 'true') {
                    return false;
                }
            }
            if (filterGroupState.name) {
                if (!(candidate.firstname.toLowerCase().includes(filterGroupState.name.toLowerCase()) ||
                    candidate.lastname.toLowerCase().includes(filterGroupState.name.toLowerCase()))) {
                    return false;
                }
            }
            return true;
        });
    };
    DatabasePage.prototype.getCandidateDescription = function (candidate) {
        var descriptions = [];
        if (candidate.incumbent) {
            descriptions.push(this.getTranslatedMessage('incumbent'));
        }
        if (candidate.elected) {
            descriptions.push(this.getTranslatedMessage('elected'));
        }
        if (candidate.yearOfBirth) {
            descriptions.push(candidate.yearOfBirth);
        }
        if (candidate.gender) {
            if (candidate.gender === 1) {
                descriptions.push(this.getTranslatedMessage('female'));
            }
            else if (candidate.gender === 2) {
                descriptions.push(this.getTranslatedMessage('male'));
            }
            else if (candidate.gender === 3) {
                descriptions.push(this.getTranslatedMessage('other'));
            }
        }
        return descriptions.reduce(function (previousValue, currentValue) {
            if (previousValue) {
                return previousValue + " | " + currentValue;
            }
            else {
                return "" + currentValue;
            }
        }, '');
    };
    DatabasePage.prototype.getTranslatedMessage = function (msg) {
        return this.translations[msg];
    };
    return DatabasePage;
}());
export { DatabasePage };
