import { NgModule } from '@angular/core'
import {
  ServerModule,
  ServerTransferStateModule,
  BEFORE_APP_SERIALIZED
} from '@angular/platform-server'
import { TransferState, makeStateKey } from '@angular/platform-browser'
import { ModuleMapLoaderModule } from '@nguniversal/module-map-ngfactory-loader'

import { AppModule } from './app.module'
import { AppComponent } from './app.component'
import { Apollo } from 'apollo-angular'

export const APOLLO_STATE_KEY = makeStateKey('apollo-state')

export function serializeApolloStateFactory(apollo: Apollo, transferStore: TransferState) {
  return () => {
    const state = apollo.getClient().extract()
    transferStore.set(APOLLO_STATE_KEY, state)
  }
}

@NgModule({
  providers: [
    {
      provide: BEFORE_APP_SERIALIZED,
      useFactory: serializeApolloStateFactory,
      deps: [Apollo, TransferState],
      multi: true
    }
  ]
})
export class ApolloStateTransferModule {}

@NgModule({
  imports: [
    // The AppServerModule should import your AppModule followed
    // by the ServerModule from @angular/platform-server.
    AppModule,
    ServerModule,
    ApolloStateTransferModule,
    ServerTransferStateModule,
    ModuleMapLoaderModule // <-- *Important* to have lazy-loaded routes work
  ],
  // Since the bootstrapped component is not inherited from your
  // imported AppModule, it needs to be repeated here.
  bootstrap: [AppComponent]
})
export class AppServerModule {}
