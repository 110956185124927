import { BrowserModule, TransferState, BrowserTransferStateModule } from '@angular/platform-browser'
import { isPlatformBrowser, isPlatformServer } from '@angular/common'
import { NgModule, Inject, LOCALE_ID, PLATFORM_ID, DoBootstrap, ApplicationRef, Injector } from '@angular/core'
import { HttpClientModule } from '@angular/common/http'

import { ApolloModule, Apollo } from 'apollo-angular'
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'

import { APOLLO_STATE_KEY } from './app.server.module'
import { environment } from '../environments/environment'

import { AppRoutingModule } from './app-routing.module'

import { AppComponent } from './app.component'

import { CoreModule } from './core/core.module'
import { SharedModule } from './shared/shared.module'
import { HomeModule } from './home/home.module'
import { QuestionnaireModule } from './questionnaire/questionnaire.module'
import { QuestionsAboutYouModule } from './questions-about-you/questions-about-you.module'
import { MatchingModule } from './matching/matching.module'
import { ResponderDetailsModule } from './responder-details/responder-details.module'
import { StaticPagesModule } from './static-pages/static-pages.module'
import { DatabaseModule } from './database/database.module'

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'svi-app' }),
    BrowserTransferStateModule,
    HttpClientModule,
    ApolloModule,
    HttpLinkModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    HomeModule,
    QuestionnaireModule.forRoot(environment.questionnaireModuleConfiguration),
    QuestionsAboutYouModule.forRoot(environment.questionsAboutYouModuleConfiguration),
    MatchingModule.forRoot(environment.matchingModuleConfiguration),
    ResponderDetailsModule,
    StaticPagesModule,
    DatabaseModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    apollo: Apollo,
    httpLink: HttpLink,
    transferState: TransferState,
    @Inject(LOCALE_ID) localeId,
    @Inject(PLATFORM_ID) private platformId,
    private injector: Injector
  ) {
    const http = httpLink.create({
      uri: environment.portierUrl
    })
    const middleware = setContext(() => ({
      headers: { 'X-Language': localeId }
    }))
    const link = middleware.concat(http)
    const fragmentMatcher = new IntrospectionFragmentMatcher({
      introspectionQueryResultData: {
        __schema: {
          types: [
            {
              kind: 'UNION',
              name: 'MatchData',
              possibleTypes: [{ name: 'Candidate' }, { name: 'Organization' }]
            }
          ]
        }
      }
    })
    const cache = new InMemoryCache({
      // dataIdFromObject: () => TODO: add if every entity has an id
      addTypename: true,
      fragmentMatcher: fragmentMatcher
    })
    if (isPlatformBrowser(this.platformId)) {
      const apolloState = transferState.get(APOLLO_STATE_KEY, undefined)
      if (apolloState) {
        cache.restore(apolloState as any)
      }
    }
    apollo.create({ link, cache, ssrMode: isPlatformServer(this.platformId) })
  }
}
