/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./core/policy-layover/policy-layover.component.ngfactory";
import * as i2 from "./core/policy-layover/policy-layover.component";
import * as i3 from "@angular/common";
import * as i4 from "./core/tokens";
import * as i5 from "./core/layout/layout.component.ngfactory";
import * as i6 from "./core/layout/layout.component";
import * as i7 from "./app.component";
import * as i8 from "./core/tracking.service";
var styles_AppComponent = [""];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "svi-policy-layover", [["link", "../assets/pdf/Privacy_policy_and_terms_of_use_for_users_smartvote_Tibet.pdf"], ["linkText", "Privacy policy and terms of use for users (PDF)"], ["text", "smartvote Tibet uses cookies and the local storage of your web browser to improve performance and usability. Using smartvote involves the processing of sensitive data (e.g., political positions of candidates and users), therefore data and privacy protection is a priority. The document below contains a detailed description of our privacy policy and general terms of use for users. We strongly encourage all users to carefully read the document."]], null, [[null, "hasAccepted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("hasAccepted" === en)) {
        var pd_0 = (_co.startTracking() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_PolicyLayoverComponent_0, i1.RenderType_PolicyLayoverComponent)), i0.ɵdid(1, 4243456, null, 0, i2.PolicyLayoverComponent, [i3.Location, i0.PLATFORM_ID, i4.LocalStorage], { text: [0, "text"], linkText: [1, "linkText"], link: [2, "link"] }, { hasAccepted: "hasAccepted" })], function (_ck, _v) { var currVal_0 = "smartvote Tibet uses cookies and the local storage of your web browser to improve performance and usability. Using smartvote involves the processing of sensitive data (e.g., political positions of candidates and users), therefore data and privacy protection is a priority. The document below contains a detailed description of our privacy policy and general terms of use for users. We strongly encourage all users to carefully read the document."; var currVal_1 = "Privacy policy and terms of use for users (PDF)"; var currVal_2 = "../assets/pdf/Privacy_policy_and_terms_of_use_for_users_smartvote_Tibet.pdf"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "svi-layout", [], null, null, null, i5.View_LayoutComponent_0, i5.RenderType_LayoutComponent)), i0.ɵdid(3, 114688, null, 0, i6.LayoutComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showLayover; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "svi-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 49152, null, 0, i7.AppComponent, [i8.TrackingService], null, null)], null, null); }
var AppComponentNgFactory = i0.ɵccf("svi-root", i7.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
