<div class="policy-layover" *ngIf="!accepted">
  <div class="policy-layover__inner">
    <div class="content">
      <div class="languages">
        <ng-container *ngFor="let language of languages">
          <a class="language" (click)="setLanguage(language.code)">{{ language.labelCode }}</a>
          <span class="separator"></span>
        </ng-container>
      </div>
      <h2 i18n="@@privacyWall.title">Privacy policy and terms of use</h2>
      <div class="text">
        <p>{{ text }}</p>
        <p>
          <a [href]="link" target="_blank">{{ linkText }}</a>
        </p>
        <p style="font-weight: bold;" i18n="@@privacyWall.importantText">
          If you wish to use smartwielen you must accept our cookie and privacy policy as well as
          the terms of use!
        </p>
      </div>
      <div class="actions">
        <button class="button button-accept" (click)="accept()" i18n="@@privacyWall.accept">
          I accept
        </button>
      </div>
    </div>
  </div>
</div>
