import { Component, Inject, LOCALE_ID } from '@angular/core'
import { Router } from '@angular/router'
import { PROFILE_MODULE_CONFIG } from '../profile.module'
import { ProfileModuleConfiguration } from '../profile.module.configuration'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'

@Component({
  selector: 'svi-smartspider-methodology',
  templateUrl: './smartspider-methodology.component.html',
  styleUrls: ['./smartspider-methodology.component.scss']
})
export class SmartspiderMethodologyComponent {
  url: SafeUrl
  constructor(private readonly router: Router,
    @Inject(PROFILE_MODULE_CONFIG) public readonly config: ProfileModuleConfiguration,
    @Inject(LOCALE_ID) public locale: string, private sanitizer: DomSanitizer
  ) {
    if (this.config.methodologyUrl) {
      this.url = this.sanitizer.bypassSecurityTrustUrl(this.config.methodologyUrl.replace('{locale}', this.locale))
    }
  }

  navigateToMethodology() {
    this.router.navigate(['/methodology'], { fragment: 'smartspider' })
  }
}
