<svi-navbar></svi-navbar>
<svi-page-title class="d-none d-sm-block" i18n>Welcome to smartwielen</svi-page-title>
<div class="home-page">
  <section class="head">
    <div class="content hero">
      <h1 i18n>Luxembourg General Election</h1>
      <span class="subheading" i18n="@@home.subheading">First Round on November 20, 2020</span><br>
      <span class="subtitle" i18n>Find candidates & parties that share your opinions!</span>
      <button class="button button-start" (click)="startQuestionnaire()">
        <ng-container i18n>Start now</ng-container>
        <i class="fas fa-angle-right"></i>
      </button>
    </div>
    <div *ngIf="recommendationCount >= 1000" class="sticker">
      <div class="content">
        <span class="text-small" i18n>Already</span>
        <span class="voter-count">{{ recommendationCountLabel }}</span>
        <span class="text-small" i18n>times used</span>
      </div>
    </div>
  </section>

  <section class="dark">
    <div class="content">
      <h2 i18n>How smartwielen works</h2>
      <div class="how-sw-works__container">
        <div class="how-sw-works">
          <div class="how-sw-works__icon candidates"></div>
          <div class="how-sw-works__title">
            1.
            <ng-container i18n>Candidates & Parties</ng-container>
          </div>
          <div class="how-sw-works__description">
            <ng-container i18n="@@home.howSmartvoteWorksCandidates"
              >Candidates & parties answer 40 questions on a wide range of current policy
              issues.</ng-container
            >
          </div>
        </div>
        <div class="how-sw-works">
          <div class="how-sw-works__icon voters"></div>
          <div class="how-sw-works__title">
            2.
            <ng-container i18n>Voters</ng-container>
          </div>
          <div class="how-sw-works__description">
            <ng-container i18n
              >Voters answer the same questions on the smartwielen website.</ng-container
            >
          </div>
        </div>
        <div class="how-sw-works">
          <div class="how-sw-works__icon matching"></div>
          <div class="how-sw-works__title">
            3.
            <ng-container i18n="@@home.howSmartvoteWorksMatchingTitle">Matching</ng-container>
          </div>
          <div class="how-sw-works__description">
            <ng-container i18n="@@home.howSmartvoteWorksMatching"
              >Voters receive a ranked list of candidates & parties that best match their political
              profile.</ng-container
            >
          </div>
        </div>
      </div>
      <div class="action">
        <button routerLink="/faq" class="button">
          <i class="fa fa-question-circle"></i>
          <ng-container i18n>FAQ</ng-container>
        </button>
      </div>
    </div>
  </section>
  <section class="white">
    <div class="content">
      <h2 i18n="@@home.audio.version.title">Play audio version</h2>
      <div class="inline-flex mb-2">
        <div class="mr-3">
          <a target="_blank" [href]="locale === 'bo' ? 'https://soundcloud.com/user-585901977/smartvote-tibets-questionnaire-tibetan-recording' : 'https://soundcloud.com/user-585901977/smartvote-tibets-questionnaire-english-recording'">
            <img width="64px" height="64px" src="../../assets/images/sc-logo.svg">
          </a>
        </div>
        <div>
          <p i18n="@@home.audio.version.description">You can play all questions also in audio format. This would be the
            text that could also be a bit longer</p>
        </div>
      </div>
      <a class="button btn-info mb-2" target="_blank" [href]="locale === 'bo' ? 'https://soundcloud.com/user-585901977/smartvote-tibets-questionnaire-tibetan-recording' : 'https://soundcloud.com/user-585901977/smartvote-tibets-questionnaire-english-recording'">
        <ng-container i18n="@@home.audio.version.open">Open Soundcloud</ng-container>
      </a>
  
    </div>
  </section>
  <section class="light">
    <div class="content">
      <h2 i18n>Who are we?</h2>
      <p>
        <ng-container i18n="@@home.whoWeAreText">
          smartwielen is a project of the University of Luxembourg in collaboration with the Zentrum
          fir politesch Bildung. The smartwielen platform is an adapted version of smartvote, a
          so-called VAA (Voting Advice Application) that was first developed in Switzerland in 2003
          and is operated by the politically neutral, non-profit organization Politools.
        </ng-container>
        <a routerLink="/about-us">
          <ng-container>&nbsp;</ng-container>
          <ng-container i18n="@@aboutUs.title">About Us</ng-container>&nbsp;
          <i class="fa fa-angle-right"></i>
        </a>
      </p>
    </div>
  </section>

  <section class="light"></section>
</div>
