/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../shared/logo/logo.component.ngfactory";
import * as i5 from "../../shared/logo/logo.component";
import * as i6 from "./footer.component";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "footer", [["class", "svi-footer container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "footer-links"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "a", [["class", "footer-link"], ["routerLink", "/methodology"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Methodology"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [["class", "footer-link"], ["routerLink", "/faq"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["FAQ"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "a", [["class", "footer-link"], ["routerLink", "/terms-and-privacy"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Terms & privacy"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "a", [["class", "footer-link"], ["routerLink", "/about-us"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["About us"])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "a", [["class", "footer-link"], ["routerLink", "/contact"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Contact"])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "svi-logo", [["size", "small"]], null, null, null, i4.View_LogoComponent_0, i4.RenderType_LogoComponent)), i1.ɵdid(18, 49152, null, 0, i5.LogoComponent, [], { size: [0, "size"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 4, "div", [["class", "copyright"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A9\u00A0 "])), (_l()(), i1.ɵeld(21, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Project Democracy / Politools"])), (_l()(), i1.ɵted(23, null, [" \u00A0", ""]))], function (_ck, _v) { var currVal_2 = "/methodology"; _ck(_v, 3, 0, currVal_2); var currVal_5 = "/faq"; _ck(_v, 6, 0, currVal_5); var currVal_8 = "/terms-and-privacy"; _ck(_v, 9, 0, currVal_8); var currVal_11 = "/about-us"; _ck(_v, 12, 0, currVal_11); var currVal_14 = "/contact"; _ck(_v, 15, 0, currVal_14); var currVal_15 = "small"; _ck(_v, 18, 0, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 6).target; var currVal_4 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 9).target; var currVal_7 = i1.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 12).target; var currVal_10 = i1.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_9, currVal_10); var currVal_12 = i1.ɵnov(_v, 15).target; var currVal_13 = i1.ɵnov(_v, 15).href; _ck(_v, 14, 0, currVal_12, currVal_13); var currVal_16 = _co.year; _ck(_v, 23, 0, currVal_16); }); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 49152, null, 0, i6.FooterComponent, [], null, null)], null, null); }
var FooterComponentNgFactory = i1.ɵccf("svi-footer", i6.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
