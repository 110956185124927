<svi-navbar backLabel="Back to questionnaire" i18n-backLabel></svi-navbar>
<svi-page-title i18n="@@userSurvey.title">
  Questions About You
</svi-page-title>
<svi-page-content>
  <div class="grid grid-reverse grid-align-top">
    <div class="cell text-right">
      <button
        class="button button-primary button-large icon-right"
        (click)="continue({saveForm: false})"
      >
        <ng-container i18n="@@userSurvey.skipUserSurvey"
          >Continue directly to your results</ng-container
        >
        <i class="fa fa-chevron-right"></i>
      </button>
    </div>
    <div class="cell">
      <p class="hint">
        <b i18n="@@userSurvey.dataNote">Please note</b>:
        <ng-container i18n="@@userSurvey.dataHandling"
          >your answers will be treated anonymously and will not affect your results</ng-container
        >
      </p>
    </div>
  </div>

  <form class="svi-questions-about-you-form" [formGroup]="form">
    <div class="section">
      <h3 i18n="@@userSurvey.biographics">Biographical information</h3>
      <div class="grid grid-gutter">
        <div class="field cell">
          <i class="fa fa-home"></i>
          <input
            class="form-control"
            type="text"
            placeholder="Country of residence"
            i18n-placeholder="@@userSurvey.countryOfResidence"
            formControlName="field_1"
          />
        </div>
        <div class="field cell">
          <div class="icon-group">
            <i class="fa fa-male"></i>
            <i class="fa fa-female"></i>
          </div>
          <select class="form-control" formControlName="drop_1">
            <option value="" i18n="@@userSurvey.drop_1.default">Gender</option>
            <option value="1" i18n="@@userSurvey.drop_1.value_1">Female</option>
            <option value="2" i18n="@@userSurvey.drop_1.value_2">Male</option>
            <option value="3" i18n="@@userSurvey.drop_1.value_3">Other</option>
          </select>
        </div>
      </div>
      <div class="grid grid-gutter">
        <div class="field cell">
          <i class="fa fa-birthday-cake"></i>
          <input class="form-control" type="text" maxlength="4" (keypress)="numberOnly($event)"
            placeholder="Year of birth" pattern="[0-9]{4}" i18n-placeholder="@@userSurvey.field_2Placeholder"
            formControlName="field_2">
        </div>
        <div class="field cell">
          <i class="fa fa-university"></i>
          <select class="form-control" formControlName="drop_2">
            <option value="" i18n="@@userSurvey.drop_2.default">Educational qualification</option>
            <option value="1" i18n="@@userSurvey.drop_2.value_1">Primary school</option>
            <option value="2" i18n="@@userSurvey.drop_2.value_2">Secondary school</option>
            <option value="3" i18n="@@userSurvey.drop_2.value_3">Bachelor's degree or equivalent</option>
            <option value="4" i18n="@@userSurvey.drop_2.value_4">Masters's degree or equivalent/option>
            <option value="5" i18n="@@userSurvey.drop_2.value_5">Doctoral degree</option>
            <option value="6" i18n="@@userSurvey.drop_2.value_6">Other</option>
            <option value="7" i18n="@@userSurvey.drop_2.value_7">Not applicable</option>
          </select>
        </div>
      </div>
      <div class="grid grid-gutter">
        <div class="field cell">
          <i class="fa fa-briefcase"></i>
          <select class="form-control" formControlName="drop_3">
            <option value="" i18n="@@userSurvey.drop_3.default">Current employment status</option>
            <option value="1" i18n="@@userSurvey.drop_3.value_1">Full-time employment</option>
            <option value="2" i18n="@@userSurvey.drop_3.value_2">Part-time employment</option>
            <option value="3" i18n="@@userSurvey.drop_3.value_3">Self-employed</option>
            <option value="4" i18n="@@userSurvey.drop_3.value_4">Unemployed</option>
            <option value="5" i18n="@@userSurvey.drop_3.value_5">Retired from employment</option>
            <option value="6" i18n="@@userSurvey.drop_3.value_6">Full-time student</option>
            <option value="7" i18n="@@userSurvey.drop_3.value_7">Caregiving or family responsibilities</option>
            <option value="7" i18n="@@userSurvey.drop_3.value_8">Other</option>
            <option value="7" i18n="@@userSurvey.drop_3.value_9">Not applicable</option>
          </select>
        </div>
        <div class="field cell">
          <i class="fa fa-crown"></i>
          <select class="form-control" formControlName="drop_4">
            <option value="" i18n="@@userSurvey.drop_4.default">Interest in politics</option>
            <option value="1" i18n="@@userSurvey.drop_4.value_1">Very interested</option>
            <option value="2" i18n="@@userSurvey.drop_4.value_2">Rather interested</option>
            <option value="3" i18n="@@userSurvey.drop_4.value_3">Neutral</option>
            <option value="4" i18n="@@userSurvey.drop_4.value_4">Rather not interested</option>
            <option value="5" i18n="@@userSurvey.drop_4.value_5">Not at all interested</option>
            <option value="6" i18n="@@userSurvey.drop_4.value_6">Other</option>
            <option value="7" i18n="@@userSurvey.drop_4.value_7">Not applicable</option>
          </select>
        </div>
      </div>
    </div>
  </form>
  <div class="grid grid-gutter grid-align-bottom section">
    <div class="cell">
      <h3 i18n="@@userSurvey.thankYou">Thank you for participating!</h3>
    </div>
    <div class="cell text-right">
      <button
        class="button button-primary button-large icon-right"
        (click)="continue({saveForm: true})"
      >
        <ng-container i18n="@@userSurvey.showResults">See results</ng-container>
        <i class="fa fa-chevron-right"></i>
      </button>
    </div>
  </div>
</svi-page-content>
