import { Component, Inject, LOCALE_ID, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { Apollo } from 'apollo-angular'
import { map, tap } from 'rxjs/operators'
import { Observable, Subscription } from 'rxjs'

import { GetRecommendationCountQuery } from '../../__generated__/types'

const { GetRecommendationCount } = require('graphql-tag/loader!./home.page.graphql')
@Component({
  selector: 'svi-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss']
})
export class HomePage implements OnDestroy {
  recommendationCountLabel = '...'
  recommendationCount = 0
  private sub: Subscription

  constructor(private router: Router, private apollo: Apollo, @Inject(LOCALE_ID) public locale: string) {
    this.sub = this.getRecommendationCount().subscribe(count => {
      this.recommendationCountLabel = count.toLocaleString('en-GB')
      this.recommendationCount = count
    })
  }
  ngOnDestroy() {
    this.sub.unsubscribe()
  }
  startQuestionnaire() {
    this.router.navigate(['matching'])
  }
  private getRecommendationCount() {
    return this.apollo
      .query<GetRecommendationCountQuery>({
        query: GetRecommendationCount
      })
      .pipe(map(({ data }) => data.statistics.recommendationCount))
  }
}
