import * as tslib_1 from "tslib";
import { TemplateRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { LastRouteService } from './last-route.service';
import { Apollo } from 'apollo-angular';
import { AnswerService } from '../core/answer.service';
import { VoterIdService } from '../core/voter-id.service';
import { getSmartmapPositions, getSmartmapLegendItems } from './matching.page';
var _a = require('graphql-tag/loader!./matching.page.graphql'), GetRecommendation = _a.GetRecommendation, GetElections = _a.GetElections;
var MatchingSharePage = /** @class */ (function () {
    function MatchingSharePage(apollo, router, route, answerService, voterIdService, _localStorage, platformId, localeId, config, lastRouteSrv) {
        var _this = this;
        this.apollo = apollo;
        this.router = router;
        this.route = route;
        this.answerService = answerService;
        this.voterIdService = voterIdService;
        this._localStorage = _localStorage;
        this.platformId = platformId;
        this.localeId = localeId;
        this.config = config;
        this.lastRouteSrv = lastRouteSrv;
        this.tabIndex = 0;
        this.showVoterPosition = true;
        this.resultSummary = {
            district: '',
            nofSeats: '',
            nofCandidates: ''
            // nofParticipatingCandidates: '' // TODO: Figure out how to get this number
        };
        this.loading = true;
        this.elections = this._getElections(); // TODO: Fix types
        this.recommendation = combineLatest([this.route.queryParams, this.elections]).pipe(switchMap(function (_a) {
            var params = _a[0], elections = _a[1];
            _this._elections = elections;
            _this.tabIndex = parseInt(params['tab'], 10) || 0;
            var recommendationId = params['rid'];
            _this.loading = true;
            if (_this._doGetRecommendation(recommendationId)) {
                return _this._getRecommendation(recommendationId, 0, -1);
            }
            else if (_this._recommendation) {
                return of(_this._recommendation);
            }
            else {
                return of(null);
            }
        }), tap(function (recommendation) {
            _this.loading = false;
            if (!recommendation) {
                return;
            }
            recommendation.positions = getSmartmapPositions(recommendation);
            recommendation.smartmapLegendItems = getSmartmapLegendItems(recommendation);
            recommendation.matchings = recommendation.matchings.map(function (matching) { return (tslib_1.__assign({}, matching, _this._getListItemLabels(matching))); });
            if (!_this._recommendation || _this._recommendation.id !== recommendation.id) {
                // Keep last state before emiting to avoid infite loop
                _this._recommendation = recommendation;
                _this._updateQueryParams({ rid: recommendation.id });
                _this._updateResultSummary(_this._elections, recommendation);
                _this._updateNofSeats(_this._elections, recommendation);
            }
        }));
    }
    MatchingSharePage.prototype.ngAfterViewInit = function () {
        this.translations = this.translationTemplate
            .createEmbeddedView({})
            .rootNodes.reduce(function (prev, curr) {
            var _a;
            return (tslib_1.__assign({}, prev, (_a = {}, _a[curr.id] = curr.textContent, _a)));
        }, {});
    };
    MatchingSharePage.prototype.onTabChanged = function (index) {
        this._updateQueryParams({ tab: index });
    };
    MatchingSharePage.prototype._updateQueryParams = function (params) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
            queryParamsHandling: 'merge'
        });
    };
    MatchingSharePage.prototype._updateResultSummary = function (elections, recommendation) {
        if (!(elections && recommendation)) {
            return;
        }
        var election = elections.find(function (e) { return e.id === recommendation.options.electionId; });
        if (!election) {
            return;
        }
        var district = election.districts.find(function (d) { return d.id === recommendation.options.districtId; });
        this.resultSummary = {
            district: district.name,
            nofSeats: "" + district.seats,
            nofCandidates: "" + recommendation.matchings.length
        };
    };
    MatchingSharePage.prototype._updateNofSeats = function (elections, recommendation) {
        if (!elections) {
            return;
        }
        var election = elections.find(function (e) { return e.id === recommendation.options.electionId; });
        if (!election) {
            return;
        }
        var district = election.districts.find(function (d) { return d.id === recommendation.options.districtId; });
        this.nofSeats = district.seats;
    };
    MatchingSharePage.prototype._getListItemLabels = function (matching) {
        var title = '';
        var description = '';
        if (matching.responderType === 'Candidate') {
            var candidate = matching.responder;
            if (candidate) {
                title = candidate.firstname + " " + candidate.lastname;
                if (candidate.nofAnswers === 0 || matching.matchValue === -9) {
                    description += " (" + this.getTranslatedMessage('no-answers') + ")";
                }
            }
            else {
                title = this.getTranslatedMessage('no-information');
            }
        }
        return { title: title, description: description, matchingValue: matching.matchValue };
    };
    MatchingSharePage.prototype._getElections = function () {
        return this.apollo
            .query({
            query: GetElections
        })
            .pipe(map(function (_a) {
            var data = _a.data;
            return data.elections;
        }));
    };
    MatchingSharePage.prototype._getRecommendation = function (recommendationId, offset, limit) {
        return this.apollo
            .query({
            query: GetRecommendation,
            variables: {
                recommendationId: recommendationId,
                offset: offset,
                limit: limit
            }
        })
            .pipe(map(function (_a) {
            var data = _a.data;
            return (tslib_1.__assign({}, data.recommendation));
        }));
    };
    MatchingSharePage.prototype._doGetRecommendation = function (rid) {
        return rid && !this._recommendation;
    };
    MatchingSharePage.prototype.onMatchingSelected = function (matching) {
        if (matching.responder) {
            if (matching.responderType === 'Candidate') {
                this.router.navigate(['profile', 'candidate', matching.responder.id], {
                    relativeTo: this.route.parent,
                    queryParamsHandling: 'preserve'
                });
            }
        }
    };
    MatchingSharePage.prototype.navigateToHome = function () {
        this.router.navigate(['home']);
    };
    MatchingSharePage.prototype.onSmartmapItemSelect = function (itemId) {
        var selectedMatching = this._recommendation.matchings.filter(function (matching) { return matching.responderId === itemId; });
        if (selectedMatching.length === 1) {
            this.onMatchingSelected(selectedMatching[0]);
        }
    };
    MatchingSharePage.prototype.getTranslatedMessage = function (msg) {
        return this.translations[msg];
    };
    return MatchingSharePage;
}());
export { MatchingSharePage };
