import { SVIVoterConfig } from './voter.configuration'

export const environment: SVIVoterConfig = {
  production: true,
  portierUrl: 'https://portier-tibet.smartvote.org/graphql',
  sharing: {
    twitterUsername: 'smartvoteTibet',
    pageGrabberUrl: 'http://page-grabber.smartvote.ch/screenshot'
  },
  emailerUrl: 'https://7degjxh594.execute-api.eu-central-1.amazonaws.com/production/send-email',
  emailerFromAddress: 'smartvote.org<noreply@smartvote.org>',
  contactFormToAddress: 'smartvote@project-democracy-tibet.org',
  matomo: {
    url: 'https://analytics.smartvote.ch',
    siteId: 6
  },
  profileModuleConfiguration: { hasSmartspider: true, showShareButton: true },
  matchingModuleConfiguration: { hasSmartmap: false, showDividingLine: true },
  questionnaireModuleConfiguration: { showWeight: false },
  questionsAboutYouModuleConfiguration: { showUserSurvey: true },
  showPrivacyLayover: true
}
