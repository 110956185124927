import {
  Component,
  Input,
  Inject,
  PLATFORM_ID,
  Output,
  EventEmitter,
  AfterViewInit
} from '@angular/core'
import { Location, isPlatformBrowser } from '@angular/common'
import { LocalStorage } from '../tokens'

const ACCEPTED_KEY = 'terms-and-policy-accepted'

export interface Language {
  labelCode: string
  code: string
  lang: string
}

@Component({
  selector: 'svi-policy-layover',
  templateUrl: './policy-layover.component.html',
  styleUrls: ['./policy-layover.component.scss']
})
export class PolicyLayoverComponent implements AfterViewInit {
  @Input()
  text: string
  @Input()
  linkText: string
  @Input()
  link: string
  @Output()
  hasAccepted = new EventEmitter()
  accepted: boolean

  languages: Language[] = [
    { labelCode: 'བོད་ཡིག', code: 'bo', lang: 'བོད་ཡིག' },
    { labelCode: 'en', code: 'en', lang: 'english' }
  ]
  constructor(
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(LocalStorage) private localStorage: Storage
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.accepted = !!this.localStorage.getItem(ACCEPTED_KEY)
    } else {
      this.accepted = true
    }
    if (!this.accepted) {
      document.querySelector('body').style.overflow = 'hidden'
    }
  }

  ngAfterViewInit() {
    if (this.accepted) {
      this.hasAccepted.emit()
    }
  }

  accept() {
    this.localStorage.setItem(ACCEPTED_KEY, 'true')
    this.accepted = true
    document.querySelector('body').style.overflow = 'auto'
    this.hasAccepted.emit()
  }

  setLanguage(language: string) {
    const newUrl = `/${language}${this.location.path(true)}`
    window.location.replace(newUrl)
  }
}
