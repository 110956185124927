/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./database.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/list-item/list-item.component.ngfactory";
import * as i3 from "../shared/list-item/list-item.component";
import * as i4 from "@angular/common";
import * as i5 from "../shared/tab-group/tab-group.component.ngfactory";
import * as i6 from "../shared/tab-group/tab-group.component";
import * as i7 from "../shared/tab/tab.component.ngfactory";
import * as i8 from "../shared/tab/tab.component";
import * as i9 from "./database-filter-group/database-filter-group.component.ngfactory";
import * as i10 from "./database-filter-group/database-filter-group.component";
import * as i11 from "@angular/forms";
import * as i12 from "../core/tracking.service";
import * as i13 from "../shared/page-content/page-content.component.ngfactory";
import * as i14 from "../shared/page-content/page-content.component";
import * as i15 from "../shared/loading/loading.component.ngfactory";
import * as i16 from "../shared/loading/loading.component";
import * as i17 from "../shared/navbar/navbar.component.ngfactory";
import * as i18 from "../shared/navbar/navbar.component";
import * as i19 from "@angular/router";
import * as i20 from "../core/tokens";
import * as i21 from "../shared/page-title/page-title.component.ngfactory";
import * as i22 from "../shared/page-title/page-title.component";
import * as i23 from "./database.page";
import * as i24 from "apollo-angular";
var styles_DatabasePage = [i0.styles];
var RenderType_DatabasePage = i1.ɵcrt({ encapsulation: 0, styles: styles_DatabasePage, data: {} });
export { RenderType_DatabasePage as RenderType_DatabasePage };
function View_DatabasePage_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-list-item", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToCandidate(_v.context.$implicit.id, _v.context.$implicit.district.electionId) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ListItemComponent_0, i2.RenderType_ListItemComponent)), i1.ɵdid(1, 49152, null, 0, i3.ListItemComponent, [], { photoUrl: [0, "photoUrl"], title: [1, "title"], description: [2, "description"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.photoUrl; var currVal_1 = ((_v.context.$implicit.firstname + " ") + _v.context.$implicit.lastname); var currVal_2 = _v.context.$implicit.description; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_DatabasePage_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_DatabasePage_4)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.candidates)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_DatabasePage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_DatabasePage_3)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = ((((tmp_0_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.candidates))) == null) ? null : tmp_0_0.length) > 0); var currVal_1 = i1.ɵnov(_v.parent.parent, 11); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_DatabasePage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "svi-database-page-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "svi-tab-group", [["class", "tabs"]], null, [[null, "selectedTabChanged"], [null, "selectedIndexChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedTabChanged" === en)) {
        var pd_0 = (_co.onTabChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("selectedIndexChange" === en)) {
        var pd_1 = ((_co.tabIndex = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_TabGroupComponent_0, i5.RenderType_TabGroupComponent)), i1.ɵdid(3, 114688, null, 1, i6.TabGroupComponent, [], { selectedIndex: [0, "selectedIndex"] }, { selectedTabChanged: "selectedTabChanged" }), i1.ɵqud(603979776, 2, { _tabs: 1 }), (_l()(), i1.ɵeld(5, 0, null, null, 8, "svi-tab", [["label", "Candidates"]], null, null, null, i7.View_TabComponent_0, i7.RenderType_TabComponent)), i1.ɵdid(6, 49152, [[2, 4]], 0, i8.TabComponent, [], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 6, "div", [["class", "svi-database-tab-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "svi-database-filter-group", [], null, [[null, "search"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("search" === en)) {
        var pd_0 = (_co.onSearch($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (_co.onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i9.View_DatabaseFilterGroupComponent_0, i9.RenderType_DatabaseFilterGroupComponent)), i1.ɵdid(9, 2801664, null, 0, i10.DatabaseFilterGroupComponent, [i11.FormBuilder, i12.TrackingService], { disabled: [0, "disabled"], state: [1, "state"], elections: [2, "elections"] }, { search: "search", reset: "reset" }), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DatabasePage_2)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabIndex; _ck(_v, 3, 0, currVal_0); var currVal_1 = "Candidates"; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.loadingdata; var currVal_3 = i1.ɵunv(_v, 9, 1, i1.ɵnov(_v, 10).transform(_co.filterGroupStateChanges)); var currVal_4 = i1.ɵunv(_v, 9, 2, i1.ɵnov(_v, 11).transform(_co.elections)); _ck(_v, 9, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.showList; var currVal_6 = i1.ɵnov(_v.parent, 10); _ck(_v, 13, 0, currVal_5, currVal_6); }, null); }
function View_DatabasePage_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "svi-page-content", [], null, null, null, i13.View_PageContentComponent_0, i13.RenderType_PageContentComponent)), i1.ɵdid(1, 49152, null, 0, i14.PageContentComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "svi-loading", [], null, null, null, i15.View_LoadingComponent_0, i15.RenderType_LoadingComponent)), i1.ɵdid(3, 49152, null, 0, i16.LoadingComponent, [], null, null)], null, null); }
function View_DatabasePage_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-list-item", [], [[2, "inactive", null]], null, null, i2.View_ListItemComponent_0, i2.RenderType_ListItemComponent)), i1.ɵdid(1, 49152, null, 0, i3.ListItemComponent, [], { isPlaceholder: [0, "isPlaceholder"] }, null)], function (_ck, _v) { var currVal_1 = true; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
function View_DatabasePage_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_DatabasePage_7)), i1.ɵdid(1, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpad(2, 4), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, 1, 2, 3, 4); _ck(_v, 1, 0, currVal_0); }, null); }
function View_DatabasePage_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "no-match"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No candidates fulfil your search criteria"]))], null, null); }
function View_DatabasePage_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["id", "elected"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Elected"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["id", "incumbent"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Incumbent"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["id", "male"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Male"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["id", "female"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Female"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["id", "other"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Other"]))], null, null); }
export function View_DatabasePage_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { translationTemplate: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "svi-navbar", [["backLabel", ""]], null, null, null, i17.View_NavbarComponent_0, i17.RenderType_NavbarComponent)), i1.ɵdid(2, 49152, null, 0, i18.NavbarComponent, [i4.Location, i19.Router, i20.LocalStorage], { backLabel: [0, "backLabel"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "svi-page-title", [], null, null, null, i21.View_PageTitleComponent_0, i21.RenderType_PageTitleComponent)), i1.ɵdid(4, 49152, [["title", 4]], 0, i22.PageTitleComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, ["Candidate profiles"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_DatabasePage_1)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["loading", 2]], null, 0, null, View_DatabasePage_5)), (_l()(), i1.ɵand(0, [["placeholder", 2]], null, 0, null, View_DatabasePage_6)), (_l()(), i1.ɵand(0, [["noMatch", 2]], null, 0, null, View_DatabasePage_8)), (_l()(), i1.ɵand(0, [[1, 2], ["translations", 2]], null, 0, null, View_DatabasePage_9))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.elections)); var currVal_2 = i1.ɵnov(_v, 9); _ck(_v, 7, 0, currVal_1, currVal_2); }, null); }
export function View_DatabasePage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-database", [], null, null, null, View_DatabasePage_0, RenderType_DatabasePage)), i1.ɵdid(1, 4243456, null, 0, i23.DatabasePage, [i24.Apollo, i19.Router, i19.ActivatedRoute, i20.LocalStorage], null, null)], null, null); }
var DatabasePageNgFactory = i1.ɵccf("svi-database", i23.DatabasePage, View_DatabasePage_Host_0, {}, {}, []);
export { DatabasePageNgFactory as DatabasePageNgFactory };
