/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./smartmap-methodology.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/accordion";
import * as i3 from "../../shared/accordion-item/accordion-item.component.ngfactory";
import * as i4 from "../../shared/accordion-item/accordion-item.component";
import * as i5 from "@angular/cdk/collections";
import * as i6 from "./smartmap-methodology.component";
import * as i7 from "@angular/router";
var styles_SmartmapMethodologyComponent = [i0.styles];
var RenderType_SmartmapMethodologyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SmartmapMethodologyComponent, data: {} });
export { RenderType_SmartmapMethodologyComponent as RenderType_SmartmapMethodologyComponent };
export function View_SmartmapMethodologyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "cdk-accordion", [], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.CdkAccordion, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "svi-accordion-item", [], null, null, null, i3.View_AccordionItemComponent_0, i3.RenderType_AccordionItemComponent)), i1.ɵdid(3, 180224, null, 0, i4.AccordionItemComponent, [[3, i2.CdkAccordion], i1.ChangeDetectorRef, i5.UniqueSelectionDispatcher], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "div", [["class", "info-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "p", [["class", "hint"]], null, null, null, null, null))], null, null); }
export function View_SmartmapMethodologyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-smartmap-methodology", [], null, null, null, View_SmartmapMethodologyComponent_0, RenderType_SmartmapMethodologyComponent)), i1.ɵdid(1, 114688, null, 0, i6.SmartmapMethodologyComponent, [i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SmartmapMethodologyComponentNgFactory = i1.ɵccf("svi-smartmap-methodology", i6.SmartmapMethodologyComponent, View_SmartmapMethodologyComponent_Host_0, {}, {}, []);
export { SmartmapMethodologyComponentNgFactory as SmartmapMethodologyComponentNgFactory };
