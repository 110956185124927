import { Component, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { EmailService } from './email.service'
import { environment } from '../../../environments/environment'
import { isPlatformBrowser } from '@angular/common'

@Component({
  selector: 'svi-contact',
  templateUrl: './contact.page.html',
  styleUrls: ['./contact.page.scss'],
  providers: [EmailService]
})
export class ContactPage implements AfterViewInit {
  form: FormGroup
  clicked = false
  sendState: any

  constructor(
    fb: FormBuilder,
    private emailer: EmailService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.form = fb.group({
      name: '',
      email: '',
      message: ''
    })
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0)
    }
  }

  getButtonColor() {
    if (typeof this.sendState === 'undefined') {
      return
    } else if (this.sendState === true) {
      return 'green'
    } else if (this.sendState === false) {
      return 'red'
    }
  }

  onSubmit() {
    this.clicked = true
    if (!this.form.valid) {
      return
    }
    this._sendEmail('svi-lux-contact-form', this.form.value)
  }

  private _sendEmail(template: string, data: any) {
    const { name, email, message } = data
    this.emailer
      .sendEmail({
        template,
        replyTo: email,
        to: environment.contactFormToAddress,
        context: {
          name,
          message,
          email,
          subject: 'Contact request - smartvote'
        }
      })
      .then(() => {
        this.sendState = true
      })
      .catch(() => {
        this.sendState = false
      })
  }
}
