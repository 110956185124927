<footer class="svi-footer container">
  <div class="footer-links">
    <a routerLink="/methodology" class="footer-link" i18n="@@methodology.title">Methodology</a>
    <a routerLink="/faq" class="footer-link" i18n>FAQ</a>
    <a routerLink="/terms-and-privacy" class="footer-link" i18n>Terms & Privacy</a>
    <a routerLink="/about-us" class="footer-link" i18n="@@aboutUs.title">About us</a>
    <a routerLink="/contact" class="footer-link" i18n>Contact</a>
  </div>
  <svi-logo size="small"></svi-logo>
  <div class="copyright">©&nbsp;
    <ng-container i18n="@@footer.copyright">Université du Luxembourg / Zentrum fir politesch Bildung (ZpB)</ng-container>
    &nbsp;{{year}}</div>
</footer>