<div class="svi-matching-filter-group">
  <form [formGroup]="form">
    <select
      #selectElection
      *ngIf="elections.length > 1"
      class="form-control"
      formControlName="election"
    >
      <option value="" i18n="@@filter.selectElection">Select Election</option>
      <option *ngFor="let election of elections" [value]="election.id" i18n>{{ election.name }}</option>
    </select>
    <select
      *ngIf="districts[0]"
      #selectDistrict
      class="form-control"
      [hidden]="districts.length === 1"
      [ngClass]="{'d-none': districts.length === 1}"
      [value]="districts.length === 1 ? districts[0].id : state.district"
      formControlName="district"
      (change)="trackSelectDistrict($event.target.value)"
    >
      <option value="" i18n="@@filter.selectDistrict">Select District</option>
      <option *ngFor="let district of districts" [value]="district.id">{{ district.name }}</option>
    </select>
    <span
      *ngIf="config.electorateHintUrl && districts[0]"
      class="electorate-hint"
    >
      Still can’t find your electorate? Go to the
      <a class="electorate-hint electorate-link" [href]="config.electorateHintUrl" target="_blank">
        Destination
      </a>
    </span>
  </form>
</div>
